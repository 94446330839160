// extracted by mini-css-extract-plugin
export var sectionBacklink = "listing-module--sectionBacklink--f68b3";
export var sectionList = "listing-module--sectionList--4f977";
export var sectionCard = "listing-module--sectionCard--3759c";
export var sectionImage = "listing-module--sectionImage--4f1dd";
export var sectionBg = "listing-module--sectionBg--5f2ee";
export var sectionName = "listing-module--sectionName--5e00e";
export var pageSwitcher = "listing-module--pageSwitcher--a5cde";
export var list = "listing-module--list--2570d";
export var post = "listing-module--post--46b41";
export var title = "listing-module--title--2bda3";
export var meta = "listing-module--meta--88e93";
export var excerpt = "listing-module--excerpt--535cd";
export var moreSoon = "listing-module--moreSoon--ae4fc";